/* eslint-disable no-unused-vars */
import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  LinearProgress,
  Button,
  Menu,
  MenuItem,
  Fade
} from "@material-ui/core";
import {
  ResponsiveContainer,
  XAxis,
  Tooltip,
  AreaChart,
  Area,
  BarChart,
  Bar
} from "recharts";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import AccessibilityNewRoundedIcon from "@material-ui/icons/AccessibilityNewRounded";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";
import AssignmentTurnedInRoundedIcon from "@material-ui/icons/AssignmentTurnedInRounded";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Page from "../../lib/components/Page";
import styles from "./dashboard.module.css";

const companyStats = `
  query {
    companyStats {
      usage {
        numerator
        denominator
      }
      meditationMins
      rakStats {
        peopleImpacted
        count
      }
      usersPackStarted
      impactScoreStats {
        impactScore
        activities
        users
      }
    }
  }`;

function timeConvert (n) {
  const num = n;
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return rhours + " h " + rminutes + " m";
}

function CompanyStats () {
  return (
    <Page query={companyStats}>
      {(data, loading) => {
        return (
          <>
            <StatsCard>
              <Box padding= "1rem 0rem 0.75rem 0.75rem" display="flex" flexDirection="row">
                <PeopleAltOutlinedIcon className={styles.card_icon}/>
                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    {"Users"}
                  </Typography>
                  {loading
                    ? <Typography variant="h6" gutterBottom><Skeleton width={100}/></Typography>
                    : <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography variant="h6" gutterBottom>
                        {data?.companyStats?.usage?.numerator}
                      </Typography>
                      <Typography variant="caption" gutterBottom style={{ padding: "0rem 0.25rem" }} color="textSecondary">
                        {"active of"}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {data?.companyStats?.usage?.denominator}
                      </Typography>
                      <Typography variant="caption" gutterBottom style={{ paddingLeft: "0.25rem" }} color="textSecondary">
                        {" users"}
                      </Typography>
                    </Box>
                  }
                </Box>
              </Box>
              <LinearProgressBar label="Usage Rate" {...data?.companyStats?.usage} />
            </StatsCard>
            <StatsCard>
              <Box display="flex" flexDirection="row" alignItems="baseline" justifyContent="space-between">
                <Box padding= "1rem 0rem 0.75rem 0.50rem" display="flex" flexDirection="row">
                  <AccessibilityNewRoundedIcon className={styles.card_icon}/>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      {"People Impacted"}
                    </Typography>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography variant="h6" gutterBottom>
                        {loading ? <Skeleton width={100} /> : data?.companyStats?.rakStats?.peopleImpacted}
                      </Typography>
                      <Typography variant="caption" gutterBottom style={{ paddingLeft: "0.25rem" }} color="textSecondary">
                        {" people"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* <FilterData/> */}
              </Box>
              <TinyBarChart/>
            </StatsCard>
            <StatsCard>
              <Box display="flex" flexDirection="row" alignItems="baseline" justifyContent="space-between">
                <Box padding= "1rem 0rem 0.75rem 0.50rem" display="flex" flexDirection="row">
                  <HourglassEmptyRoundedIcon className={styles.card_icon}/>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      {"Mindful Minutes"}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {loading ? <Skeleton width={100}/> : timeConvert(data?.companyStats?.meditationMins)}
                    </Typography>
                  </Box>
                </Box>
                {/* <FilterData/> */}
              </Box>
              <TinyLineChart/>
            </StatsCard>
            <StatsCard>
              <Box padding= "1rem 0rem 0.75rem 0.50rem" display="flex" flexDirection="row">
                <AssignmentTurnedInRoundedIcon className={styles.card_icon}/>
                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    {"Impact Score"}
                  </Typography>
                  {loading
                    ? <Typography variant="h6" gutterBottom><Skeleton width={100}/></Typography>
                    : <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography variant="h6" gutterBottom>
                        {data?.companyStats?.impactScoreStats?.activities}
                      </Typography>
                      <Typography variant="caption" gutterBottom style={{ padding: "0rem 0.25rem" }} color="textSecondary">
                        {"activities by"}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {data?.companyStats?.impactScoreStats?.users}
                      </Typography>
                      <Typography variant="caption" gutterBottom style={{ paddingLeft: "0.25rem" }} color="textSecondary">
                        {" users"}
                      </Typography>
                    </Box>
                  }
                </Box>
              </Box>
              <LinearProgressBar label="Impact Score" numerator={data?.companyStats?.impactScoreStats?.impactScore * 100} denominator={100}/>
            </StatsCard>
          </>
        );
      }}
    </Page>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  }
}));

const StatsCard = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid item lg={3} md={6} sm={6} xs={12}>
      <Paper variant="outlined" className={classes.root}>
        {children}
      </Paper>
    </Grid>
  );
};

const normalize = (value = 0, MAX = 0, MIN = 0) => (value - MIN) * 100 / (MAX - MIN);

const LinearProgressBar = ({ label, numerator = 0, denominator = 0 }) => {
  const usersUsageRate = Math.round(numerator > 0 ? normalize(numerator, denominator) : numerator);
  return (<Box padding= "0rem 1rem">
    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
      {`${label} ${usersUsageRate}%`}
    </Typography>
    <LinearProgress variant="determinate" value={usersUsageRate}/>
  </Box>);
};

const companyMindfulMinutesStats = `
  query {
    companyMindfulMinutesStats {
      date
      value
    }
  }`;
const TinyLineChart = () => {
  return (
    <Page query={companyMindfulMinutesStats}>
      {(data, loading) => {
        return <>{
          loading
            ? <Box padding= "2rem 1rem"><LinearProgress variant="indeterminate"/></Box>
            : (<ResponsiveContainer width={"100%"} height={60}>
              <AreaChart data={data?.companyMindfulMinutesStats}>
                <XAxis dataKey="date" hide={true}/>
                <Tooltip
                  position={{ y: -70 }}
                  cursor={false}
                  separator=""
                  formatter={(value, name, props) => ([`${timeConvert(value)}`, ""])}
                />
                <Area type='monotone' dataKey='value' stroke='#479db3' strokeWidth={2} fill='rgb(182, 188, 226)' fillOpacity={0.3}/>
              </AreaChart>
            </ResponsiveContainer>)
        }</>;
      }}
    </Page>

  );
};

const companyPeopleImpactedStats = `
  query {
    companyPeopleImpactedStats {
      date
      value
    }
  }`;
const TinyBarChart = () => {
  return (
    <Page query={companyPeopleImpactedStats}>
      {(data, loading) => {
        return <>{
          loading
            ? <Box padding= "2rem 1rem"><LinearProgress variant="indeterminate"/></Box>
            : (<ResponsiveContainer width={"100%"} height={60}>
              <BarChart data={data?.companyPeopleImpactedStats}>
                <XAxis dataKey="date" hide={true}/>
                <Tooltip
                  position={{ y: -70 }}
                  cursor={false}
                  separator=""
                  formatter={(value, name, props) => ([`${value} people impacted`, ""])}
                />
                <Bar dataKey='value' fill='#479db3'/>
              </BarChart>
            </ResponsiveContainer>
            )
        }</>;
      }}
    </Page>
  );
};

const FilterData = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (<>
    <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} endIcon={<ArrowDropDownOutlinedIcon/>}>
      {"Last 7 days"}
    </Button>
    <Menu
      id="fade-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <MenuItem onClick={handleClose}>Last 7 days</MenuItem>
      {/* <MenuItem onClick={handleClose}>Last 30 days</MenuItem>
      <MenuItem onClick={handleClose}>Last 3 months</MenuItem> */}
    </Menu></>
  );
};

export default CompanyStats;
