import React, { useMemo } from "react";
import { useQuery } from "urql";
import { Container, Button, Box } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

import Table from "../../lib/components/Table/ControlledTable";
import UsageReportDownload from "./UsageReportDownload";

import { USAGE_REPORT_COLUMNS } from "../../lib/constants/columnConstants";

const companyUsersQuery = `
  query($limit: Int, $offset: Int, $sort: UserSortInput, $search: String) {
    companyUsers(input: {limit: $limit, offset: $offset, sort: $sort, search: $search}) {
      data {
        id
        name
        email
        mindfulnessMinutes
        peopleImpacted
        totalPoints
        impactScore
        state
      }
      pageInformation {
        totalElements
        hasNext
      }
    }
  }
`;

function UsageReport () {
  const [pageInfo, setPageInfo] = React.useState({ offset: 0, limit: 10, search: "" });
  const columns = useMemo(() => USAGE_REPORT_COLUMNS, []);
  const [result, executeQuery] = useQuery({
    query: companyUsersQuery,
    variables: {
      ...pageInfo,
      sort: { field: "MINDFULNESS_MINUTES", direction: "DESC" }
    }
  });

  const fetchData = React.useCallback(({ pageSize, pageIndex, globalFilter }) => {
    setPageInfo({ offset: pageSize * pageIndex, limit: pageSize, search: globalFilter });
  }, []);

  const { data, fetching, error } = result;
  if (error) {
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        <p>Oh no... {error.message}</p>
        <Button variant="outlined" color="primary" onClick={executeQuery}>
          <ReplayIcon />
          Retry
        </Button>
      </Box>
    );
  }
  return (<Container maxWidth="lg">
    <UsageReportDownload/>
    <Table
      data={data?.companyUsers?.data ?? []}
      pageCount={Math.round(data?.companyUsers?.pageInformation?.totalElements / pageInfo.limit) ?? 0}
      pageInformation={data?.companyUsers?.pageInformation ?? {
        totalElements: 0,
        hasNext: false
      }}
      pageInfo={pageInfo}
      fetchData={fetchData}
      loading={fetching}
      columns={columns}
    />
  </Container>);
}

export default UsageReport;
