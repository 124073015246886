/* eslint-disable no-unused-vars */
import React from "react";
import { useClient } from "urql";
import Papa from "papaparse";
import to from "await-to-js";
import { useSnackbar } from "notistack";
import FileSaver from "file-saver";
import { Box, Button } from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";

const toJson = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete (results, file) {
        resolve(results);
      },
      error (err, file) {
        reject(err);
      }
    });
  });
};

const validateData = (fields, data) => {
  const labels = ["email", "firstName", "lastName"];
  let valid = false;
  let errorMessage;
  if (!Array.isArray(data) || !data.length) {
    errorMessage = "CSV file is empty";
  } else if (!Array.isArray(fields) || !fields.length) {
    errorMessage = "Headers are missing in CSV file";
  } else if (!labels.every(r => fields.includes(r))) {
    errorMessage = `Invalid data CSV must contain ${labels}`;
  } else {
    valid = true;
  }
  return [valid, errorMessage];
};

const addUsers = `
  mutation addUsers($user: [CreateCompanyUserForm!]!){
    createCompanyUsers(companyUserForms: $user) {
        id,
        email
        name
    }
  }`;

function UploadUserDialog () {
  const graphqlClient = useClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = async ({ target }) => {
    const file = target.files[0];
    if (file) {
      const [err, results] = await to(toJson(file));
      const { data: jsonData, meta: { fields }, error } = results;
      const [valid, errorMessage] = validateData(fields, jsonData);
      if (valid) {
        const user = jsonData.map((row) => ({
          email: row.email,
          name: `${row.firstName} ${row.lastName}`
        }));
        const variables = {
          user
        };
        const { data, error } = await graphqlClient.mutation(addUsers, variables).toPromise();

        if (error) {
          const errorMessage = error.networkError
            ? error.message
            : error.message.split(":")[1].trim();
          enqueueSnackbar(errorMessage, {
            variant: "error"
          });
        } else {
          enqueueSnackbar(`${data.createCompanyUsers.length} Users Uploaded`, {
            variant: "success"
          });
        }
      } else {
        enqueueSnackbar(errorMessage, {
          variant: "error"
        });
      }
    }
    target.value = "";
  };

  const downloadTemplate = () => {
    const fileData = Papa.unparse([{ firstName: "", lastName: "", email: "" }], {
      quotes: false, // or array of booleans
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
      columns: ["firstName", "lastName", "email"] // or array of strings
    });

    const blob = new Blob([fileData], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "users_upload_template.csv");
  };

  return (
    <Box display="flex" flexDirection="column">
      <Button size="small" color="primary" onClick={downloadTemplate} style={{ textDecoration: "underline" }}>download template</Button>
      <input
        type="file"
        id="fileUploadButton"
        style={{ display: "none" }}
        accept=".csv"
        onChange={handleUpload}
      />
      <label htmlFor={"fileUploadButton"}>
        <Button
          component="span"
          variant="outlined"
          color="primary"
          startIcon={<CloudUploadOutlinedIcon />}
        >
            Upload Users
        </Button>
      </label>
    </Box>
  );
}

export default UploadUserDialog;
