/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Duration from "luxon/src/duration.js";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RepeatIcon from "@material-ui/icons/Repeat";
import styles from "./pack.module.css";

function Pack ({ data }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button
        style={{ marginTop: "0.75rem" }}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        {"Know more"}
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent style={{ overflow: "auto" }}>
          <Box flexDirection="row" justifyContent="center" textAlign="center">
            <img
              className={styles.media}
              src={data.imageUrl}
              alt={data.name}
            />
            <Typography gutterBottom variant="h6" component="h6">
              {data.name}
            </Typography>
            <Typography
              style={{ marginBottom: "auto", padding: "0 1rem" }}
              gutterBottom
              variant="body1"
              color="textSecondary"
              component="p"
            >
              {data.description}
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between" padding="0.75rem 0">
              <PackDetail label="DURATION" value={`${Duration.fromISO(data.duration).days} Days`} icon={<CalendarTodayOutlinedIcon fontSize="small"/>}/>
              <PackDetail
                label="TIME REQUIRED"
                value={`${Duration.fromISO(data.minTimePerDay).minutes} - ${Duration.fromISO(data.maxTimePerDay).minutes} Min`}
                icon={<ScheduleIcon fontSize="small"/>}
              />
              <PackDetail label="RECOMMEND CYCLES" value={`${data.minRequiredCycles} cycles`} icon={<RepeatIcon fontSize="small"/>}/>
            </Box>
            <Divider />
            <Typography
              style={{ padding: "0.75rem 0rem" }}
              variant="body2"
            >
              {`${data.userCount} people completed`}
            </Typography>

            <PackOutcomes data={data.benefitDescription.points}/>

            <Typography
              style={{ padding: "0.75rem 0rem", textAlign: "left" }}
              variant="h6"
            >
              {"Check out the activities you'd do"}
            </Typography>
            <Box className={styles.pack_activities}>
              {data?.schedule[0]?.tasks?.map((activity) => <PackActivity key={activity.uuid} {...activity}/>)}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Pack;

const PackDetail = ({ icon, label, value }) => (
  <Box display="flex" flexDirection="column" textAlign="left" className={styles.pack_detail}>
    {icon}
    <Typography
      style={{ marginBottom: "auto" }}
      gutterBottom
      variant="caption"
      color="textSecondary"
      component="p"
    >
      {label}
    </Typography>
    <Typography
      style={{ marginBottom: "auto" }}
      gutterBottom
      variant="subtitle2"
      component="p"
    >
      {value}
    </Typography>
  </Box>
);

const PackActivity = ({ imageUrl, name, metadata }) => (
  <Box className={styles.pack_activity}>
    <img
      className={styles.media}
      src={imageUrl}
      alt={name}
    />
    <Typography gutterBottom variant="subtitle1" >
      {name}
    </Typography>
  </Box>
);

const PackOutcomes = ({ data }) => (
  <Box>
    <Typography
      style={{ paddingBottom: "0.75rem", textAlign: "left" }}
      variant="h6"
    >
      {"Outcomes"}
    </Typography>
    <Grid container spacing={1} className={styles.pack_outcomes}>
      {data.map((outcome, index) => (
        <Grid item sm={6} key={outcome} >
          <Typography variant="body2">{outcome}</Typography>
        </Grid>
      ))}
    </Grid>
  </Box>
)
;
