/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Grid } from "@material-ui/core";
import CompanyStats from "./CompanyStats";
import Leaderboard from "./Leaderboard";

function Dashboard () {
  return (
    <Container maxWidth={"lg"}>
      <Grid container spacing={2} >
        <CompanyStats/>
        <Leaderboard/>
      </Grid>
    </Container>
  );
}

export default Dashboard;
