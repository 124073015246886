import React, { useState } from "react";
import {
  Divider,
  Box,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  makeStyles
} from "@material-ui/core";

import Page from "../../lib/components/Page";
import useInterval from "./../../lib/hooks/useInterval";
import { ACTIVITY_POLL } from "./../../lib/constants";

const userActivities = `
query {
    companyUserActivities{
        id
        trait
        taskType
        user {
            name
            imageUrl
        }
        packTask {
            uuid
            ... on PackRak {
                name 
            }
            ... on Track {
                name
                playerType
                metadata{
                    fileName
                    durationInSeconds
                }
            }
        }
    }
}
`;

const useStyles = makeStyles((theme) => ({
  activity: {
    height: "100%"
  },
  root: {
    width: "100%",
    height: "90%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 0
  },
  inline: {
    display: "inline"
  },
  listText: {
    margin: 0
  }
}));

function UserActivities () {
  const classes = useStyles();
  const [count, setCount] = useState(0);

  useInterval(() => {
    console.log("check to poll : ", document.visibilityState === "visible");
    if (document.visibilityState === "visible") {
      setCount(count + 1);
    }
  }, ACTIVITY_POLL);

  return (
    <Page query={userActivities} variables={{ count }}>
      {(data, loading) => (
        <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
          <Paper variant="outlined" className={classes.activity}>
            <Box
              padding= "1.5rem 1rem 1rem 1rem"
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6">Activity Feed</Typography>
            </Box>
            <Divider />
            <List className={classes.root}>
              {data?.companyUserActivities?.map((row, index) => <UserActivity key={row.id} classes={classes} data={row}/>)}
            </List>
          </Paper>
        </Grid>
      )}
    </Page>
  );
}

export default UserActivities;

const UserActivity = ({ data, classes }) => {
  const { user, taskType, packTask } = data;
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar alt={user.name} src={user.imageUrl} />
      </ListItemAvatar>
      <ListItemText
        className={classes.listText}
        primary={user.name}
        secondary={
          <>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {getTaskType(taskType)}
            </Typography>
            <br></br>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textSecondary"
            >
              {` ${packTask?.name ?? ""}`}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

const getTaskType = (taskType) => {
  switch (taskType) {
  case "JOURNAL": return `Added a ${upperFirst(taskType.toLowerCase())}`;
  case "MINDFUL_ACT": return "Did a Mindful Act";
  case "EXTERNAL_GRATITUDE": return "Did a External Gratitude";
  case "READ_RAK_STORY": return "Read a RAK story";
  default: return `Did a ${upperFirst(taskType.toLowerCase())}`;
  }
};

const upperFirst = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};
