import React from "react";
import { useQuery } from "urql";
import { Button, Box, Container } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

function Page ({ query, variables, children }) {
  const [result, reexecuteQuery] = useQuery({
    query,
    variables
  });

  const { data, fetching, error } = result;
  if (error) {
    const errorMessage = error.networkError
      ? error.message
      : error.message?.split(":")[1]?.trim() ?? "Internal server error";
    return (
      <Container>
        <Box display="flex" alignItems="center" flexDirection="column">
          <p>{errorMessage}</p>
          <Button variant="outlined" color="primary" onClick={reexecuteQuery}>
            <ReplayIcon />
            {"Retry"}
          </Button>
        </Box>
      </Container>
    );
  }
  if (data || fetching) { return <>{children(data, fetching)}</>; }
  return null;
}

export default Page;
