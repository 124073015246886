import React from "react";
import { TextField, Container, Button } from "@material-ui/core";
import { useForm } from "react-hook-form";

function Support () {
  const { register, handleSubmit } = useForm();
  // eslint-disable-next-line no-console
  const onSubmit = (data) => console.log(data);

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Message"
          id="message"
          name="message"
          inputRef={register}
          placeholder="Please type your message here..."
          fullWidth
          multiline
          margin="normal"
          variant="outlined"
          rows={10}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Container>
  );
}

export default Support;
