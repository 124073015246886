import React from "react";
import {
  Box,
  // Paper,
  Typography
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

function timeConvert (n) {
  const num = n;
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return rhours + " h " + rminutes + " m";
}

const formatter = {
  peopleImpacted: (value, name, props) => ([`${value} people impacted`, ""]),
  mindfulnessMinutes: (value, name, props) => ([`${timeConvert(value)}`, ""])
};

function PointsGraph ({ data, xAxisDataKey }) {
  const [yAxisKey, setYAxisKey] = React.useState("peopleImpacted");
  const handleAlignment = (event, newYAxisKey) => {
    setYAxisKey(newYAxisKey);
  };
  return (
    <Box>
      <Box display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding= "1rem 1rem"
        alignItems="center"
      >
        <ToggleButtonGroup
          value={yAxisKey}
          exclusive
          onChange={handleAlignment}
          aria-label="toggle chart"
        >
          <ToggleButton value="peopleImpacted" aria-label="people impacted">
            <Typography color="primary">
              {"People Impacted"}
            </Typography>
          </ToggleButton>
          <ToggleButton value="mindfulnessMinutes" aria-label="mindfulness minutes">
            <Typography color="primary">
              {"Mindfulness Minutes"}
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <ResponsiveContainer width={"100%"} aspect={3}>
        <BarChart data={data} margin={{ top: 16, right: 16, left: 16, bottom: 16 }}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey={xAxisDataKey}/>
          <YAxis/>
          <Tooltip
            position={{ y: -70 }}
            cursor={false}
            separator=""
            formatter={formatter[yAxisKey]}
          />
          <Tooltip position={{ y: 0 }}/>
          <Bar dataKey={yAxisKey} fill="#479db3" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default PointsGraph;
