/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Paper, Grid, Card, CardContent, CardMedia, Typography, Box } from "@material-ui/core";
import { Duration } from "luxon";
import Skeleton from "@material-ui/lab/Skeleton";

import Page from "../../lib/components/Page";
import Pack from "./Pack";

import styles from "./packs.module.css";

const packsQuery = `
  query {
    packsPreview {
      data {
        uuid
        name
        imageUrl
        description
        detailedDescription
        duration
        minTimePerDay
        maxTimePerDay
        minRequiredCycles
        userCount
        benefitDescription {
          points
        }
        schedule {
          tasks {
            uuid
            name
            imageUrl
            metadata{
              minDuration
              maxDuration
            }
          }
        }
      }
    }
  }`;

function Packs () {
  return (
    <Container maxWidth={"lg"}>
      <Grid container spacing={2}>
        <Page query={packsQuery}>
          {(data, loading) => (<>
            {(loading
              ? [...new Array(6)]
                .map((d, index) => ({ uuid: index }))
              : data?.packsPreview?.data)
              .map((pack) =>
                (<PackCard loading={loading} data={pack} key={pack.uuid}/>)
              )}
          </>)}
        </Page>
      </Grid>
    </Container>
  );
}

export default Packs;

const PackCard = ({ loading, data }) => {
  return (
    <Grid item lg={4} md={6}>
      <Paper variant="outlined" >
        <Box display="flex" flexDirection="row" className={styles.card} >
          <Box display="flex" flexDirection="column" className={styles.content} justifyContent="space-between">
            <Typography gutterBottom variant="h6" component="h6">
              {loading
                ? <Skeleton />
                : data.name
              }
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {loading
                ? <Skeleton />
                : data.description}
            </Typography>
            {loading
              ? <Skeleton />
              : <Pack data={data}/>}
          </Box>
          {loading
            ? <Skeleton variant="rect" height="100%"/>
            : <img
              className={styles.media}
              src={data.imageUrl}
              alt={data.name}
            />}
        </Box>
      </Paper>
    </Grid>
  );
};
