import React from "react";

import UserProfile from "./UserProfile";

import styles from "./header.module.css";

function Header () {
  return (
    <div className={styles.header}>
      <div className={styles.item_right}>
        <UserProfile />
      </div>
    </div>
  );
}

export default Header;
