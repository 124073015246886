import React from "react";
import {
  Container,
  Grid,
  Paper,
  Box,
  Card,
  CardContent,
  TextField,
  Typography
} from "@material-ui/core";
import Page from "../../lib/components/Page";

import Skeleton from "@material-ui/lab/Skeleton";

import styles from "./profile.module.css";

const ProfileQuery = `
  query {
    company {
      uuid
      name
      email
      imageUrl
      website
      address {
        street
        city
        postalCode
        state
        country
      }
      contactInfo {
        name
        countryCode
        phoneNo
      }
    }
  }
`;

function Profile () {
  return (
    <Page query={ProfileQuery}>
      {(data, loading) => (
        <Container maxWidth="md">
          <Paper variant="outlined" className={styles.profile}>
            <Grid container spacing={2} >
              <Grid item lg={4} md={4} >
                <Card variant="outlined">
                  <CardContent>
                    <Box
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                    >
                      {loading
                        ? <Skeleton variant="rect" width={220} height={150}/>
                        : <img
                          className={styles.media}
                          src={data?.company?.imageUrl}
                          alt={data?.company?.name}
                        />}
                    </Box>
                  </CardContent>
                  {/* <Divider />
                      <CardActions>
                        <Button color="primary" fullWidth variant="contained">
                          Upload picture
                        </Button>
                      </CardActions>
                */}
                </Card>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} >
                <Grid container spacing={3} >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SkeletonTextField
                      loading={loading}
                      fullWidth
                      id="name"
                      label="Name"
                      variant="outlined"
                      value={data?.company?.name ?? ""}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SkeletonTextField
                      loading={loading}
                      fullWidth
                      id="website"
                      label="Website"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      value={data?.company?.website ?? ""}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SkeletonTextField
                      loading={loading}
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      id="contactInfo-email"
                      label="Email"
                      variant="outlined"
                      value={data?.company?.email ?? ""}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" color="textSecondary">
                      Address
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SkeletonTextField
                  loading={loading}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  id="street"
                  label="Street"
                  variant="outlined"
                  value={data?.company?.address?.street ?? ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} >
                <SkeletonTextField
                  loading={loading}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  id="city"
                  label="City"
                  variant="outlined"
                  value={data?.company?.address?.city ?? ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} >
                <SkeletonTextField
                  loading={loading}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  id="postalCode"
                  label="Pin Code"
                  variant="outlined"
                  value={data?.company?.address?.postalCode ?? ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} >
                <SkeletonTextField
                  loading={loading}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  id="state"
                  label="State"
                  variant="outlined"
                  value={data?.company?.address?.state ?? ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} >
                <SkeletonTextField
                  loading={loading}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  id="country"
                  label="Country"
                  variant="outlined"
                  value={data?.company?.address?.country ?? "India"}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" color="textSecondary">Contact Info</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SkeletonTextField
                  loading={loading}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  id="contactInfo-name"
                  label="Name"
                  variant="outlined"
                  value={data?.company?.contactInfo?.name ?? ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} >
                <SkeletonTextField
                  loading={loading}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  id="contactInfo-phoneNo"
                  label="Phone No"
                  variant="outlined"
                  value={`${data?.company?.contactInfo?.countryCode ?? ""} - ${data?.company?.contactInfo?.phoneNo ?? ""}`}
                />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      )}
    </Page>
  );
}

export default Profile;

const SkeletonTextField = ({ loading, ...props }) => {
  if (loading) {
    return <Skeleton height="2rem"/>;
  } else {
    return (<TextField {...props} />);
  }
};
