import React from "react";

import { TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const GlobalFilter = ({
  totalElements,
  globalFilter,
  setGlobalFilter
}) => {
  return (
    <TextField
      type="search"
      variant="standard"
      margin="normal"
      onChange={(event) => {
        if (event.target.value === "") {
          event.preventDefault();
          setGlobalFilter(event.target.value);
        }
      }}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          setGlobalFilter(event.target.value);
        }
      }}
      placeholder={`${totalElements} records...`}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />);
};

export default GlobalFilter;
