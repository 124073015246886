import React from "react";
import ReactDOM from "react-dom";
import { Button } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
/* rest of app styles */
import "./index.css";
import App from "./App";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#479db3",
      dark: "#002884",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ffa726",
      main: "#ff9800",
      dark: "#ff9800",
      contrastText: "#000"
    }
  },
  typography: {
    button: {
      textTransform: "Capitalize"
    }
  }
});

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        action={(key) => (
          <Button onClick={onClickDismiss(key)}>
                Dismiss
          </Button>
        )}
      >
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
