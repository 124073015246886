import { AUTH_URL, AUTH_STATE, CLIENT, ACCESS_TOKEN, UID, USER_ID, NAME, IMAGE } from "./../constants";

const login = async (data) => {
  const response = await fetch(`${AUTH_URL}/sign_in`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    });
  const jsonData = await response.json();

  if (response.ok) {
    if (jsonData.role === "ADMIN") {
      localStorage.setItem(AUTH_STATE, true);
      localStorage.setItem(CLIENT, response.headers.get("client"));
      localStorage.setItem(ACCESS_TOKEN, response.headers.get("access-token"));
      localStorage.setItem(UID, jsonData.uid);
      localStorage.setItem(USER_ID, jsonData.id);
      localStorage.setItem(NAME, jsonData.name);
      localStorage.setItem(IMAGE, jsonData.image);
    } else {
      return { errors: ["Unauthorized access please contact BL support"] };
    }
  }
  return jsonData;
};

const getAuthState = () => {
  return !!localStorage.getItem(AUTH_STATE) || false;
};

const logout = async () => {
  const headers = {
    "Content-Type": "application/json",
    "access-token": localStorage.getItem(ACCESS_TOKEN),
    client: localStorage.getItem(CLIENT),
    uid: localStorage.getItem(UID),
    userId: localStorage.getItem(USER_ID),
    subscriptionState: "FREE"
  };

  localStorage.removeItem(AUTH_STATE);
  localStorage.removeItem(CLIENT);
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(UID);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(NAME);
  localStorage.removeItem(IMAGE);

  const response = await fetch(`${AUTH_URL}/sign_out`,
    {
      method: "DELETE",
      headers,
      body: JSON.stringify({ email: localStorage.getItem(UID) })
    });
  return response;
};

export { login, getAuthState, logout };
