import React from "react";
import { useClient } from "urql";
import { useSnackbar } from "notistack";
import Papa from "papaparse";
import FileSaver from "file-saver";
import { Button, Box } from "@material-ui/core";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

const allCompanyUsersQuery = `
  query {
    allCompanyUsers {
        name
        email
        mindfulnessMinutes
        peopleImpacted
        totalPoints
        impactScore
    }
  }
`;

function Exporter () {
  const graphqlClient = useClient();
  const { enqueueSnackbar } = useSnackbar();
  const download = async () => {
    try {
      const { data, error } = await graphqlClient.query(allCompanyUsersQuery).toPromise();
      if (error) {
        enqueueSnackbar(error.message, {
          variant: "error"
        });
      } else if (data) {
        const fileData = Papa.unparse(data?.allCompanyUsers ?? [], {
          quotes: false, // or array of booleans
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: true,
          newline: "\r\n",
          skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
          columns: ["name", "email", "mindfulnessMinutes", "peopleImpacted", "totalPoints", "impactScore"] // or array of strings
        });
        const blob = new Blob([fileData], { type: "text/csv;charset=utf-8;" });
        FileSaver.saveAs(blob, "users-usage-report.csv");
      }
    } catch (error) {
      const errorMessage = error.networkError
        ? error.message
        : error.message.split(":")[1].trim();
      enqueueSnackbar(errorMessage, {
        variant: "Error"
      });
    }
  };

  return (
    <Box marginBottom="1rem" display="flex" justifyContent="flex-end" >
      <Button
        variant="outlined"
        color="primary"
        startIcon={<CloudDownloadOutlinedIcon />}
        onClick={download}
      >Download</Button>
    </Box>
  );
}

export default Exporter;
