import React from "react";
import { useClient } from "urql";
import {
  TextField,
  Container,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  Switch,
  makeStyles
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { object, string, mixed, lazy } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 160,
    marginRight: "2rem"
  },
  radioGroup: {
    flexDirection: "row"
  }
}));

const sendNotification = `
  mutation sendNotification($input: UserNotificationForm!){
    notifyUsers(input: $input) {
      uuid
      userFilter
    }
  }`;

const notificationData = lazy(value => {
  if (value !== undefined) {
    return object().shape({
      title: string().required("Title is required"),
      body: string().required("Body is required")
    });
  }
  return mixed().notRequired();
});

const schema = object().shape({
  userFilter: mixed()
    .oneOf(["ALL", "PACK_NOT_STARTED", "SEVEN_DAYS_INACTIVE", "ONE_MONTH_INACTIVE"])
    .default("ALL"),
  push: notificationData,
  email: notificationData
});

function Notification () {
  const [state, setState] = React.useState({
    push: false,
    email: false
  });
  const graphqlClient = useClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { register, errors, handleSubmit, control } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (formData) => {
    const variables = {
      input: formData
    };
    // eslint-disable-next-line no-unused-vars
    const { data, error } = await graphqlClient.mutation(sendNotification, variables).toPromise();

    if (error) {
      // const errorMessage = error.networkError
      //   ? error.message
      //   : error.message.split(":")[1].trim();

      enqueueSnackbar(error.message, {
        variant: "error"
      });
    } else {
      enqueueSnackbar("Notification Sent", {
        variant: "success"
      });
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Filter</InputLabel>
          <Controller
            control={control}
            name="userFilter"
            defaultValue="ALL"
            as={
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Filter"
                defaultValue="ALL"
              >
                <MenuItem value="ALL">All</MenuItem>
                <MenuItem value="PACK_NOT_STARTED">Pack Not Started</MenuItem>
                <MenuItem value="SEVEN_DAYS_INACTIVE">Inactive for 7 Days</MenuItem>
                <MenuItem value="ONE_MONTH_INACTIVE">Inactive for 1 Month</MenuItem>
              </Select>
            }
          />
        </FormControl>
        <FormGroup >
          <FormControlLabel
            control={
              <Switch
                checked={state.push}
                onChange={handleChange}
                name="push"
                color="primary"
              />}
            label="Push Notification"
          />
          {state.push && <>
            <TextField
              label="Title"
              id="title"
              name="push.title"
              placeholder="Please type your title here..."
              fullWidth
              multiline
              margin="normal"
              variant="outlined"
              rows={1}
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register}
              error={!!errors?.push?.title}
              helperText={errors?.push?.title ? errors.push?.title?.message : ""}
            />
            <TextField
              label="Body"
              id="body"
              name="push.body"
              placeholder="Please type your body here..."
              fullWidth
              multiline
              margin="normal"
              variant="outlined"
              rows={10}
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register}
              error={!!errors?.push?.body}
              helperText={errors?.push?.body ? errors?.push?.body?.message : ""}
            />
          </>
          }
          <FormControlLabel
            control={
              <Switch
                checked={state.email}
                onChange={handleChange}
                name="email"
                color="primary"
              />
            }
            label="Email Notification"
          />
          {state.email && <>
            <TextField
              label="Title"
              id="title"
              name="email.title"
              placeholder="Please type your title here..."
              fullWidth
              multiline
              margin="normal"
              variant="outlined"
              rows={1}
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register}
              error={!!errors?.email?.title}
              helperText={errors?.email?.title ? errors.email?.title?.message : ""}
            />
            <TextField
              label="Body"
              id="body"
              name="email.body"
              placeholder="Please type your body here..."
              fullWidth
              multiline
              margin="normal"
              variant="outlined"
              rows={10}
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register}
              error={!!errors?.email?.body}
              helperText={errors?.email?.body ? errors?.email?.body?.message : ""}
            />
          </>
          }
        </FormGroup>
        <Button disabled={!(state.push || state.email)} type="submit" variant="contained" color="primary">
            Send
        </Button>
      </form>

    </Container>
  );
}

export default Notification;
