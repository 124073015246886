import React from "react";

import logo from "./../../../assets/images/logo-with-name.png";
import styles from "./logo.module.css";

function Logo ({ sidebar = false, src = logo }) {
  return (
    <div className={`${styles.logo} ${sidebar ? styles.logo_sidebar : ""}`}>
      <img className={styles.img} src={src} alt="Logo" />
    </div>
  );
}

export default Logo;
