export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const ACTIVITY_POLL = process.env.REACT_APP_ACTIVITY_POLL;

// local storage keys
export const AUTH_STATE = "isAuthenticated";
export const CLIENT = "client";
export const ACCESS_TOKEN = "access_token";
export const UID = "uid";
export const USER_ID = "user_id";
export const NAME = "name";
export const IMAGE = "image";
