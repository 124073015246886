import React from "react";
import { Avatar, Button, Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { logout } from "./../../services/authService";
import { NAME, IMAGE } from "./../../constants";

import styles from "./userProfile.module.css";

function UserProfile () {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async (event) => {
    setAnchorEl(event.currentTarget);
    await logout();
    enqueueSnackbar("Logged out successfully", {
      variant: "success"
    });
    history.push("/login");
  };

  return (
    <>
      <Button
        className={styles.user_profile}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar className={styles.margin_right} src={localStorage.getItem(IMAGE)}/>
        <div className={styles.margin_right}>{localStorage.getItem(NAME)}</div>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default UserProfile;
