import React from "react";
import { NavLink } from "react-router-dom";
import Spacer from "../Spacer";
import { Paper, Typography, Grid } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PersonIcon from "@material-ui/icons/Person";
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
// import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import { DateTime } from "luxon";
import Skeleton from "@material-ui/lab/Skeleton";

import Page from "./../Page";
import Logo from "./../Logo";
import styles from "./sidebar.module.css";

const sidebar = [
  {
    path: "/dashboard",
    label: "Dashboard",
    icon: <DashboardOutlinedIcon className={styles.list_icon} />
  }
];

const sidebarReports = [
  {
    path: "/usage-report",
    label: "Usage Report",
    icon: <AssessmentOutlinedIcon className={styles.list_icon} />
  }
];

const sidebarManage = [
  {
    path: "/users",
    label: "Users",
    icon: <PeopleAltOutlinedIcon className={styles.list_icon} />
  },
  {
    path: "/packs",
    label: "Packs",
    icon: <ViewListOutlinedIcon className={styles.list_icon} />
  },
  {
    path: "/profile",
    label: "Profile",
    icon: <PersonIcon className={styles.list_icon} />
  },
  {
    path: "/notification",
    label: "Notification",
    icon: <EmailOutlinedIcon className={styles.list_icon}/>
  }
];

function Sidebar () {
  return (
    <div className={styles.sidebar}>
      {sidebar.map((link) => (
        <NavLink
          key={link.path}
          to={link.path}
          className={styles.list_item}
          activeClassName={styles.active}
        >
          {link.icon}
          {link.label}
        </NavLink>
      ))}
      <Spacer value={"1.5rem"} />
      <span className={styles.list_item_header}>Reports</span>
      {sidebarReports.map((link) => (
        <NavLink
          key={link.path}
          to={link.path}
          className={styles.list_item}
          activeClassName={styles.active}
        >
          {link.icon}
          {link.label}
        </NavLink>
      ))}
      <Spacer value={"1.5rem"} />
      <span className={styles.list_item_header}>Manage</span>
      {sidebarManage.map((link) => (
        <NavLink
          key={link.path}
          to={link.path}
          className={styles.list_item}
          activeClassName={styles.active}
        >
          {link.icon}
          {link.label}
        </NavLink>
      ))}
      {/* <Spacer value={"1.5rem"} />
      <span className={styles.list_item_header}>Get Help</span>
      <NavLink
        to="/support"
        className={styles.list_item}
        activeClassName={styles.active}
      >
        <ContactSupportOutlinedIcon className={styles.list_icon} />
        Support
      </NavLink> */}
      <CompanyPlanInfo />
      <Logo sidebar={false}/>
    </div>
  );
}

const activeCompanyPlanQuery = `
  query {
      activeCompanyPlan{
        id
        started
        expires
      }
  }
`;

const CompanyPlanInfo = () => {
  return (
    <Page query={activeCompanyPlanQuery}>
      {(data, loading) => (
        <Paper className={styles.sidebar_info} variant="outlined">
          <Typography variant="subtitle1" >
            Start Plan
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Started
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {loading
                ? <Skeleton/>
                : <Typography variant="body2" color="textSecondary">
                  {`: ${DateTime.fromISO(data?.activeCompanyPlan?.started).toFormat(
                    "LLL yyyy"
                  )}`}
                </Typography>}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Expires on
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {loading
                ? <Skeleton/>
                : <Typography variant="body2" color="textSecondary">
                  {`: ${DateTime.fromISO(data?.activeCompanyPlan?.expires).toFormat(
                    "LLL yyyy"
                  )}`}
                </Typography>}
            </Grid>
            {/* <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Total Users
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {loading
                ? <Skeleton/>
                : <Typography variant="body2" color="textSecondary">
                  {`: ${data?.activeCompanyPlan?.usersCount} Users`}
                </Typography>}
            </Grid> */}
          </Grid>
        </Paper>
      )}
    </Page>
  );
};

export default Sidebar;
