export const USER_COLUMNS = [
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "State",
    accessor: "state"
  }
];

export const USAGE_REPORT_COLUMNS = [
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "Mindfulness Minutes",
    accessor: "mindfulnessMinutes"
  },
  {
    Header: "People Impacted",
    accessor: "peopleImpacted"
  },
  {
    Header: "Total Points",
    accessor: "totalPoints"
  },
  {
    Header: "Impact Score",
    accessor: row => row.impactScore.toFixed(2)
  }
];
