import React from "react";
import { Link } from "react-router-dom";

import Page from "./../Page";
import styles from "./company-logo.module.css";

const companyLogoQuery = `
  query {
    companyLogo
  }
`;

function CompanyLogo () {
  return (
    <Page query={companyLogoQuery}>
      {(data, loading) => (
        <Link to="/dashboard" className={`${styles.logo} ${styles.logo_sidebar}`}>
          <img className={styles.img} src={data?.companyLogo} alt="company logo" />
        </Link>)}
    </Page>
  );
}

export default CompanyLogo;
