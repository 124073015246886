import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { createClient, Provider as GraphqlProvider } from "urql";

import Header from "./lib/components/Header";
import Sidebar from "./lib/components/Sidebar";
import CompanyLogo from "./lib/components/CompanyLogo";
import PrivateRoute from "./lib/components/PrivateRoute";

import Login from "./features/auth/Login";
import Users from "./features/Users";
import Dashboard from "./features/Dashboard";
import Profile from "./features/Profile";
import Packs from "./features/Packs";
import Support from "./features/Support";
import UsageReport from "./features/UsageReport";
import Notification from "./features/Notification";
import { API_URL, CLIENT, ACCESS_TOKEN, UID, USER_ID } from "./lib/constants";

import "./App.css";

const client = createClient({
  url: API_URL,
  // requestPolicy: "network-only",
  fetchOptions: () => {
    return {
      headers: {
        "access-token": localStorage.getItem(ACCESS_TOKEN),
        client: localStorage.getItem(CLIENT),
        uid: localStorage.getItem(UID),
        userId: localStorage.getItem(USER_ID),
        subscriptionState: "FREE"
      }
    };
  }
});

function App () {
  return (
    <Router>
      <Switch>
        <Route path="/login" >
          <Login />
        </Route>
        <PrivateRoute path="/">
          <GraphqlProvider value={client}>
            <Default />
          </GraphqlProvider>
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

const Default = () => {
  return (
    <div className="grid-container">
      <CompanyLogo/>
      <Header />
      <Sidebar />
      <main className="main">
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route path="/dashboard" exact>
            <Dashboard />
          </Route>
          <Route path="/usage-report">
            <UsageReport/>
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/packs">
            <Packs />
          </Route>
          <Route path="/users/:id">
            <Users />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/notification">
            <Notification/>
          </Route>
          <Route path="/support">
            <Support />
          </Route>
        </Switch>
      </main>
    </div>
  );
};

export default App;
