import React, { useState } from "react";
import { useClient } from "urql";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import AddIcon from "@material-ui/icons/Add";

const schema = object().shape({
  firstName: string().required("First Name is required"),
  lastName: string().required("Last Name is required"),
  email: string().email("Valid email is required").required("Email is required")
});

const addUser = `
  mutation addUsers($user: [CreateCompanyUserForm!]!){
    createCompanyUsers(companyUserForms: $user) {
        id,
        email
        name
    }
  }`;

const AddUserDialog = (props) => {
  const graphqlClient = useClient();
  const { enqueueSnackbar } = useSnackbar();
  const { register, errors, handleSubmit } = useForm({ resolver: yupResolver(schema) });
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = async (user, e) => {
    const variables = {
      user: [{
        name: `${user.firstName} ${user.lastName}`,
        email: user.email
      }]
    };
    const { error } = await graphqlClient.mutation(addUser, variables).toPromise();

    if (error) {
      const errorMessage = error.networkError
        ? error.message
        : error.message.split(":")[1].trim();
      enqueueSnackbar(errorMessage, {
        variant: "error"
      });
    } else {
      enqueueSnackbar("User Added", {
        variant: "success"
      });
      e.target.reset();
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Add User
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <form onSubmit={handleSubmit(handleAdd)}>
          <DialogContent>
            <TextField
              autoFocus
              name="firstName"
              label="First Name"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register}
              error={!!errors.firstName}
              helperText={errors.firstName ? errors.firstName.message : ""}
            />
            <TextField
              name="lastName"
              label="Last Name"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register}
              error={!!errors.lastName}
              helperText={errors.lastName ? errors.lastName.message : ""}
            />
            <TextField
              name="email"
              label="email"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Close
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddUserDialog;
