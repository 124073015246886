/* eslint-disable no-unused-vars */
import React from "react";

import clsx from "clsx";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import GlobalFilter from "../../lib/components/Table/GlobalFilter";
import Button from "@material-ui/core/Button";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: "black"
        // backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: "black"
        // backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: "1 1 100%"
  }
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    totalElements,
    setGlobalFilter,
    globalFilter,
    enableUserHandler,
    disableUserHandler
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0
        ? (<Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>)
        : (<GlobalFilter
          totalElements={totalElements}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />)}

      {numSelected > 0
        ? (<>
          <Tooltip title="Enable">
            <Button
              variant="outlined"
              color="primary"
              aria-label="enable"
              style={{ marginRight: "1rem" }}
              onClick={enableUserHandler}
            >
              <CheckBoxIcon />
              Enable
            </Button>
          </Tooltip>
          <Tooltip title="Disable">
            <Button
              aria-label="disable"
              variant="outlined"
              color="secondary"
              onClick={disableUserHandler}
            >
              <CheckBoxOutlineBlankIcon />
              Disable
            </Button>
          </Tooltip>
        </>)
        : null
      }
    </Toolbar>
  );
};

export default TableToolbar;
