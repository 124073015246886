import React from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "./TablePaginationActions";
import { Box, Toolbar } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import GlobalFilter from "./GlobalFilter";

function ControlledTable ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  pageInformation,
  pageInfo
}) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable({
    columns,
    data,
    initialState: { ...pageInfo },
    manualPagination: true,
    manualGlobalFilter: true,
    pageCount: controlledPageCount
  },
  useGlobalFilter,
  usePagination
  );

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, globalFilter });
  }, [fetchData, pageIndex, pageSize, globalFilter]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <Box border={1}>
      <Toolbar>
        <GlobalFilter
          totalElements={pageInformation.totalElements}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}/>
      </Toolbar>
      <TableContainer>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, idx) => (
              <TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell key={column.id} {...column.getHeaderProps()}>
                    <b>{column.render("Header")}</b>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {loading
              ? [...new Array(pageSize)].map(() =>
                headerGroups.map((headerGroup, idx) => (
                  <TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell key={column.id} >
                        <Skeleton width="100%"/>
                      </TableCell>
                    ))}
                  </TableRow>
                )))
              : rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow key={row.id} {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <TableCell key={`${row.id}-${cell.column.id}`} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10
                ]}
                colSpan={3}
                count={pageInformation.totalElements}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage }
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
      </TableContainer>
    </Box>
  );
}

export default ControlledTable;
