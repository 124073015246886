import React from "react";
import { useClient } from "urql";
import { useSnackbar } from "notistack";
import { Container, Box } from "@material-ui/core";

import Page from "../../lib/components/Page";
import Table from "../../lib/components/Table/EnhancedTable";
import AddUserDialog from "./AddUserDialog";
import UploadUserDialog from "./UploadUserDialog";

const companyUsersQuery = `
  query($limit:Int, $offset:Int, $sort:UserSortInput) {
    companyUsers(input: {limit: $limit, offset: $offset, sort: $sort}) {
      data {
        id
        name
        email
        state
      }
      pageInformation {
        totalElements
        hasNext
      }
    }
  }
`;

const updateCompanyUsersState = `
  mutation updateCompanyUsersState($userIds: [ID!]!, $state: UserState!){
    updateCompanyUsersState(userIds: $userIds, state: $state) {
        id,
        email
        name
    }
  }`;

function Users () {
  const graphqlClient = useClient();
  const { enqueueSnackbar } = useSnackbar();

  const updateState = async (variables) => {
    const { data, error } = await graphqlClient.mutation(updateCompanyUsersState, variables).toPromise();

    if (error) {
      const errorMessage = error.networkError
        ? error.message
        : error.message.split(":")[1].trim();
      enqueueSnackbar(errorMessage, {
        variant: "error"
      });
    } else {
      enqueueSnackbar(`${data.updateCompanyUsersState.length} Users Updated`, {
        variant: "success"
      });
    }
  };
  return (
    <Container maxWidth="lg">
      <Box marginBottom="1rem" display="flex" justifyContent="flex-end" alignItems="flex-end">
        <AddUserDialog/>
        <Box paddingRight={2}/>
        <UploadUserDialog/>
      </Box>
      <Page
        query={companyUsersQuery}
        variables={{
          offset: 0,
          limit: 1050,
          sort: { field: "ID", direction: "DESC" }
        }}
      >
        {(data, loading) => (
          <Table
            loading={loading}
            updateState={updateState}
            data={data?.companyUsers?.data ?? []}
            totalCount={data?.companyUsers?.pageInformation?.totalElements ?? 0}
            columns={[
              {
                Header: "Name",
                accessor: "name"
              },
              {
                Header: "Email",
                accessor: "email"
              },
              {
                Header: "State",
                accessor: "state"
              }
            ]}
          />
        )}
      </Page>
    </Container>
  );
}

export default Users;
