import React from "react";
import {
  Container,
  Box,
  TextField,
  Button
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";

import { login } from "./../../lib/services/authService";
import Logo from "./../../lib/components/Logo";

import styles from "./login.module.css";

const schema = object().shape({
  email: string().email("Valid email is required").required("Email is required"),
  password: string().required("Password is required")
});

function Login () {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    try {
      const response = await login(data);
      if (response.errors) {
        enqueueSnackbar(response.errors[0], {
          variant: "error"
        });
      } else {
        history.push("/");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar("Internal server error. Please try again later", {
        variant: "error"
      });
    }
  };

  return (
    <Container maxWidth="xs" className={styles.login}>
      <Box mt={3} mb={1}>
        <Logo/>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          label="Email Address"
          margin="normal"
          name="email"
          type="text"
          variant="outlined"
          inputRef={register}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ""}
        />
        <TextField
          fullWidth
          label="Password"
          margin="normal"
          name="password"
          type="password"
          variant="outlined"
          inputRef={register}
          error={!!errors.password}
          helperText={errors.password ? errors.password.message : ""}
        />
        <Box my={2}>
          <Button
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
              Sign in
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default Login;
