import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getAuthState } from "./../services/authService";

function PrivateRoute ({ children, ...rest }) {
  const isAuthenticated = getAuthState();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated
          ? (children)
          : (<Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />)
      }
    />
  );
}

export default PrivateRoute;
