/* eslint-disable no-unused-vars */
import React from "react";
import {
  Divider,
  Box,
  Grid,
  Typography,
  Avatar,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  makeStyles
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton, Skeleton } from "@material-ui/lab";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";

import UserActivities from "./UserActivities";

import Page from "../../lib/components/Page";
import PointsGraph from "./PointsGraph";

const leaderboardQuery = `
  query {
    leaderboard {
      id
      name
      email
      imageUrl
      totalPoints
      peopleImpacted
      mindfulnessMinutes
      points{
        reflection
        action
        reinforce
        evaluation
      }
      impactScore
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: "1rem"
  },
  cell: {
    borderBottom: "none"
  },
  leaderboard: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

const Leaderboard = () => {
  const classes = useStyles();
  return (<>
    <UserActivities/>
    <Page query={leaderboardQuery}>
      {(data, loading) => (<>
        <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
          <Paper variant="outlined" className={classes.leaderboard}>
            <Box>
              <Box display="flex"
                flexDirection="row"
                justifyContent="space-between"
                padding= "1rem 1rem"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormatListNumberedIcon htmlColor="#ff6004" style={{ paddingRight: "0.5rem" }}/>
                  <Typography variant="h6">Leaderboard</Typography>
                </Box>
                <ToggleButtonGroup
                  value="ALL_TIME"
                  exclusive
                  // onChange={handleAlignment}
                  aria-label="toggle chart"
                >
                  <ToggleButton value="ALL_TIME" aria-label="all time">
                    <Typography color="primary">
                      {"All Time"}
                    </Typography>
                  </ToggleButton>
                  {/* <ToggleButton value="DAILY" aria-label="daily">
                  <Typography color="primary">
                    {"Daily"}
                  </Typography>
                </ToggleButton>
                <ToggleButton value="MONTHLY" aria-label="monthly">
                  <Typography color="primary">
                    {"Monthly"}
                  </Typography>
                </ToggleButton> */}
                </ToggleButtonGroup>
              </Box>
              <TableContainer >
                <Table aria-label="simple table" size="small" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"><b>Rank</b></TableCell>
                      <TableCell align="center"><b>Name</b></TableCell>
                      <TableCell align="center"><b>Points</b></TableCell>
                      <TableCell align="center"><b>People Impacted</b></TableCell>
                      <TableCell align="center"><b>Mindfulness Minutes</b></TableCell>
                      <TableCell align="center"><b>Impact Score</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading
                      ? [...new Array(5)].map((d, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.cell} align="center"><Skeleton/></TableCell>
                          <TableCell className={classes.cell} component="th" scope="row" align="center">
                            <Box display="flex" alignItems="center">
                              <Box margin={1}>
                                <Skeleton variant="circle">
                                  <Avatar />
                                </Skeleton>
                              </Box>
                              <Typography>
                                <Skeleton width="5rem"/>
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell className={classes.cell} align="center"><Skeleton/></TableCell>
                          <TableCell className={classes.cell} align="center"><Skeleton/></TableCell>
                          <TableCell className={classes.cell} align="center"><Skeleton/></TableCell>
                        </TableRow>
                      ))
                      : data.leaderboard.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell className={classes.cell} align="center">{index + 1}</TableCell>
                          <TableCell className={classes.cell} component="th" scope="row" align="center">
                            <Box display="flex" alignItems="center">
                              <Avatar
                                className={classes.large}
                                src={row.imageUrl}
                              />
                              <Typography>
                                {row.name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell className={classes.cell} align="center">{row.totalPoints}</TableCell>
                          <TableCell className={classes.cell} align="center">{row.peopleImpacted}</TableCell>
                          <TableCell className={classes.cell} align="center">{row.mindfulnessMinutes}</TableCell>
                          <TableCell className={classes.cell} align="center">{row.impactScore.toFixed(2)}</TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
            </Box>
            <PointsGraph
              data={data?.leaderboard ?? []}
              loading={loading}
              xAxisDataKey="name"
            />
          </Paper>
        </Grid>
      </>
      )}
    </Page>
  </>
  );
};

export default Leaderboard;
